<template>
  <v-dialog
    persistent
    v-model="active"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.inventory.textTitle }}</p>
      <div class="d-flex text-center justify-center break-line">
        <p class="text-description mon-bold mr-1">
          {{ texts.inventory.textActual}}
        </p>
        <p class="text-description mon-regular">
          {{ formatNumber(quantityInventory) }}
          {{ nameUnit }}.
        </p>
      </div>
      <!-- <p class="text-subtitle mon-regular">
        {{
          (selectLanguage == 'sp')
          ? 'El precio no cambiará cuando se actualice la cantidad de inventario.'
          : 'The price will not change when the inventory quantity is updated.'
        }}
      </p> -->
      <div class="mt-4">
        <v-text-field
          v-model="lotNumber"
          type="text"
          :label="texts.inventory.textNumLot"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-4"
          :placeholder="texts.inventory.textNumLot"
          persistent-placeholder
          maxLength="18"
        ></v-text-field>
        <div>
          <div class="display-flex align-items-center mb-4">
            <v-text-field
              v-model="quantityBoxes"
              type="text"
              :label="placeText"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="placeText"
              persistent-placeholder
              @keypress="soloNumbers"
              maxLength="6"
            ></v-text-field>
            <div
              class="ml-2"
              style="width: 150px"
            >
              <v-select
                v-model="selectOption"
                outlined
                color="#000000"
                dense
                min-width="150px"
                class="global-inputs mon-regular"
                :items="texts.inventory.options"
                item-text="text"
                item-value="value"
                persistent-placeholder
              ></v-select>
            </div>
          </div>
          <!-- <p class="result-inventory mon-regular">
            {{ texts.inventory.textResultInventory }} {{ resultInventory() }}
            {{ nameUnit }}
          </p> -->
        </div>
        <p class="text-estimated-title mon-bold">
          {{
            selectLanguage == "sp"
              ? "Fecha y hora en que el artículo estará disponible para su recolección."
              : "Date and time the item will be available for pickup."
          }}
        </p>
        <div class="display-flex align-items-center">
          <v-menu
            ref="bMenuFinal"
            v-model="bMenuFinal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                outlined
                color="#000000"
                dense
                class="global-inputs inputs-date mon-regular"
                readonly
                v-model="bstartDateTextField"
                clearable
                hide-details
                append-icon="mdi-calendar"
                @click:clear="startDate = null"
                :placeholder="texts.inventory.textDisponibilityPlaceholder"
                :label="texts.inventory.textDisponibility"
                persistent-hint
                persistent-placeholder
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="#C41F79"
              v-model="startDate"
              @change="changeFormatDateMenuFinal()"
              no-title
              @input="bMenuFinal = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu1"
            v-model="time1"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="articleDisponibilityHour"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="articleDisponibilityHour"
                :label="texts.inventory.txtLabelHoraEstimada"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                color="#000000"
                class="global-inputs mon-regular ml-2 mt-1"
                :placeholder="texts.inventory.txtLabelHoraEstimadaPlaceholder"
                persistent-placeholder
                suffix="24 Hrs"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="time1"
              v-model="articleDisponibilityHour"
              full-width
              @click:minute="$refs.menu1.save(articleDisponibilityHour)"
              format="24hr"
              header-color="#151d27"
              color="#151d27"
            ></v-time-picker>
          </v-menu>
        </div>
        <div class="d-flex text-center">
          <p class="result-inventory mon-bold mt-2">
            {{ selectLanguage == "sp" ? "Nota:" : "Note:" }}
          </p>
          <p class="result-inventory mon-regular mt-2">
            {{
              selectLanguage == "sp"
                ? "La actualización de inventario no afectará el precio del artículo previamente definido. Para modificar el precio es necesario dirigirse al detalle del artículo en la opción modificar."
                : "The inventory update will not affect the price of the previously defined item. To modify the price it is necessary to go to the detail of the article in the modify option."
            }}
          </p>
        </div>
      </div>
      <div v-if="isResponsive">
        <v-btn
          @click="save"
          :disabled="!validateForm"
          :loading="bLoading"
          elevation="0"
          class="button-delete mon-bold"
          style="width: 100%;"
        >
          {{ texts.inventory.buttons.save }}
        </v-btn>
        <v-btn
          @click="closeDialog"
          elevation="0"
          class="button-cancel mon-regular mt-5"
          style="width: 100%;"
        >
          {{ texts.inventory.buttons.cancel }}
        </v-btn>
      </div>
      <div
        v-else
        class="display-flex align-items-center mt-9"
      >
        <v-btn
          @click="closeDialog"
          class="button-cancel mon-regular"
          elevation="0"
        >
          {{ texts.inventory.buttons.cancel }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="button-delete mon-bold ml-5"
          elevation="0"
          @click="save"
          :disabled="!validateForm"
          :loading="bLoading"
        >
          {{ texts.inventory.buttons.save }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ArticleDetailModifyInventoryLayout",
  data() {
    return {
      //VARIABLES
      bMenuFinal: false,
      startDate: null,
      bstartDateTextField: null,
      lotNumber: "",
      quantityBoxes: null,
      selectOption: true,
      texts: "",
      screenWidth: 0,
      nameUnit: "",
      quantityInventory: 0,
      options: "",
      time1: null,
      articleDisponibilityHour: "",
      bLoading: false,
      isResponsive: false,
      placeText: "",
    };
  },
  beforeMount() {
    this.texts = FILE.inventory[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    formatNumber: function (e) {
      return e.toLocaleString("es-US");
    },
    setDates(date) {
      let hour = this.articleDisponibilityHour.split(":")[0];
      let minutes = this.articleDisponibilityHour.split(":")[1];
      let today = new Date(date);
      today.setDate(today.getDate() + 1);
      today.setHours(hour, minutes);
      return today;
    },
    resultInventory: function () {
      let res = 0;
      if (this.selectOption == true) {
        res = Number(this.quantityInventory) + Number(this.quantityBoxes);
      } else {
        res = Number(this.quantityInventory) - Number(this.quantityBoxes);
      }
      return res;
    },
    save: function () {
      this.bLoading = true;

      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {
          dQuantity:
            this.selectOption == true
              ? `${this.quantityBoxes}`
              : `-${this.quantityBoxes}`,
          tProductAvailableTime: this.setDates(this.startDate), //this.startDate,
          sLotId: this.lotNumber,
        };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/products/${this.arr.sProductId}/inventory`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 35;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setDialogInventory", {
        active: false,
        arr: [],
      });
      this.bMenuFinal = false;
      this.startDate = null;
      this.bstartDateTextField = null;
      this.lotNumber = "";
      this.quantityBoxes = null;
      this.selectOption = true;
      this.articleDisponibilityHour = "";
      this.nameUnit = "";
      this.quantityInventory = 0;
      this.options = "";
    },
    changeFormatDateMenuFinal() {
      let aDate = this.startDate.split("-");

      let day = aDate[2];
      let month = aDate[1];
      let year = aDate[0];
      let date = month + "/" + day + "/" + year;
      this.bstartDateTextField = date;
    },
  },
  computed: {
    // return create contact dialog state
    active: function () {
      return this.$store.state.activeInventory;
    },
    arr: function () {
      return this.$store.state.arrInventory;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.lotNumber !== "" &&
        this.quantityBoxes !== "" &&
        this.startDate !== null &&
        this.articleDisponibilityHour !== ""
      );
    },
  },
  watch: {
    active() {
      if (this.active) {
        this.texts = FILE.inventory[this.selectLanguage];

        this.nameUnit = this.arr.oMeasurementUnit.sName;
        this.placeText = this.texts.inventory.textQuantiy + this.nameUnit;
        this.quantityInventory = this.arr.dInventoryQuantity;
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.inventory[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.result-inventory {
  letter-spacing: 0px;
  /* color: black !important; */
  /* opacity: 1; */
  font-size: 10px;
  /* margin-top: -15px; */
  /* opacity: 0.6; */
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}

.text-estimated-title {
  text-align: left;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 14px;
  margin-bottom: 5px;
}

.text-subtitle {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 12px;
}

.text-description {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.button-cancel {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  height: 40px !important;
  text-transform: initial;
  padding-left: 35px !important;
  padding-right: 35px !important;
  background-color: #ffffff !important;
}

.button-delete {
  height: 40px !important;
  text-transform: initial;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  padding-left: 35px !important;
  padding-right: 35px !important;
}

@media (max-width: 960px) {
  .break-line {
    display: block !important;
  }
}
</style>